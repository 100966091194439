exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-printing-guide-js": () => import("./../../../src/pages/articles/printing-guide.js" /* webpackChunkName: "component---src-pages-articles-printing-guide-js" */),
  "component---src-pages-articles-translation-guide-js": () => import("./../../../src/pages/articles/translation-guide.js" /* webpackChunkName: "component---src-pages-articles-translation-guide-js" */),
  "component---src-pages-attribution-js": () => import("./../../../src/pages/attribution.js" /* webpackChunkName: "component---src-pages-attribution-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locale-url-base-articles-printing-guide-js": () => import("./../../../src/pages/{Locale.urlBase}/articles/printing-guide.js" /* webpackChunkName: "component---src-pages-locale-url-base-articles-printing-guide-js" */),
  "component---src-pages-locale-url-base-articles-translation-guide-js": () => import("./../../../src/pages/{Locale.urlBase}/articles/translation-guide.js" /* webpackChunkName: "component---src-pages-locale-url-base-articles-translation-guide-js" */),
  "component---src-pages-locale-url-base-attribution-js": () => import("./../../../src/pages/{Locale.urlBase}/attribution.js" /* webpackChunkName: "component---src-pages-locale-url-base-attribution-js" */),
  "component---src-pages-locale-url-base-help-js": () => import("./../../../src/pages/{Locale.urlBase}/help.js" /* webpackChunkName: "component---src-pages-locale-url-base-help-js" */),
  "component---src-pages-locale-url-base-index-js": () => import("./../../../src/pages/{Locale.urlBase}/index.js" /* webpackChunkName: "component---src-pages-locale-url-base-index-js" */),
  "component---src-pages-locale-url-base-privacy-policy-js": () => import("./../../../src/pages/{Locale.urlBase}/privacy-policy.js" /* webpackChunkName: "component---src-pages-locale-url-base-privacy-policy-js" */),
  "component---src-pages-locale-url-base-team-js": () => import("./../../../src/pages/{Locale.urlBase}/team.js" /* webpackChunkName: "component---src-pages-locale-url-base-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

